<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">旅游效益年度纪录</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item prop="ND" label="年份" class="from-item-block">
                    <el-date-picker
                        v-model="formdata.dataDic.ND"
                        type="year"
                        placeholder="选择年份"
                        value-format="yyyy"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    prop="MPSR"
                    label="门票收入(元)"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.MPSR"
                        @input="toDecimal($event, 'MPSR')"
                        @blur="blurMoney('MPSR')"
                        @focus="focusMoney('MPSR')"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="GLBMJYYFWSR"
                    label="管理部门经营与服务收入(元)"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.GLBMJYYFWSR"
                        @input="toDecimal($event, 'GLBMJYYFWSR')"
                        @blur="blurMoney('GLBMJYYFWSR')"
                        @focus="focusMoney('GLBMJYYFWSR')"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="CSXGGZHJYDJMSL"
                    label="从事相关工作和经营的居民数量(人)"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.CSXGGZHJYDJMSL"
                        @input="toNumber($event, 'CSXGGZHJYDJMSL')"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "lyxyndjl",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "1104",
                dataDic: {
                    glycbtid: this.heritageId,
                    ND: "",
                    MPSR: "",
                    GLBMJYYFWSR: "",
                    CSXGGZHJYDJMSL: "",
                },
            },
            rules: {
                ND: [
                    {
                        required: true,
                        message: "请选择年份",
                        trigger: "change",
                    },
                ],
            },
            enumData: [],
            toMoneyProps: ["MPSR", "GLBMJYYFWSR"],
        };
    },
};
</script>

<style>
.yearBox .yearItem {
    width: 48% !important;
}
.yearBox .separate {
    padding: 0 10px;
}
</style>
